(() => {
    document.querySelector('#contact-form').addEventListener('submit', () => {
        event.preventDefault();
        console.log(`
          Name: ${event.target.elements.name.value}
          Email: ${event.target.elements.email.value}
          Subject: ${event.target.elements.subject.value}
          Message: ${event.target.elements.message.value}
        `);

        //send email here
        //var formMessage = "Subject: ${event.target.elements.subject.value} " <br>Message: " + ${event.target.elements.message.value};
        var TotalMessage = "CONTACT REQUEST <br>Name: " + event.target.elements.name.value + "<br>Email: " + event.target.elements.email.value + "<br>Subject: " + event.target.elements.subject.value + "<br>Message: " + event.target.elements.message.value;
        //console.log("NEW REQUEST: " + TotalMessage);

        var retval = POSTHandler(TotalMessage);
        console.log("POST RESULT: " + retval);
        
        if(retval == 0){
	        document.getElementById("contact-console").style.color = "#f00404";
            document.getElementById("contact-console").innerHTML = "Sorry we are having an error connecting to server.  Please email info@protonify.com.";
            
            /*ResetData();*/
    	}else{
           /* document.getElementById("contact-console").classList.add('col-purple');
           */
            document.getElementById("contact-console").style.color = "#0c9b42";
            document.getElementById("contact-console").innerHTML = "Message sent!";
            
            /*ResetData();*/
            document.getElementById("name-field").value = "";
            document.getElementById("email-field").value = "";
            document.getElementById("subject-field").value = "";
            document.getElementById("message-field").value = "";
    	}

    });


    const modals = document.getElementsByClassName('modal');
    for (let i = 0; i < modals.length; i++) {
        const modal = modals[i];
        document.getElementById(`modalButton-${i}`).onclick = () => {
            modal.style.display = 'block';
        };
        document.getElementById(`modalCloseIcon-${i}`).onclick = () => {
            modal.style.display = 'none';
        };
        document.getElementById(`modalCloseButton-${i}`).onclick = () => {
            modal.style.display = 'none';
        };
    }
    window.onclick = function(event) {
        if (event.target.className === 'modal') {
            event.target.style.display = 'none';
        }
    };

    const links = document.getElementsByClassName('link');
    for (let i = 0; i < links.length; i++) {
        links[i].onclick = () => {
            document.getElementById('nav-check').checked = false;
        };
    }
})();

function POSTHandler(TotalMessage){
	var retdata = 0;
    var urlToGet = "/php/contact.php";
	console.log("URL To GET: "+urlToGet);
	console.log("Post Message: "+TotalMessage);
	
	$.ajax({
        type:"POST",
        url: urlToGet,
        data:{"contactTxt":encodeURI(TotalMessage)},
        success: function(data){
            retdata = data;
        },
        error: function(xhr, ajaxOptions, thrownError){
            console.log("Unable to GET ER001 - " + thrownError.toString() );
            return retdata;
        },
        async: false
    });

    console.log("Success RetData: "+retdata);
    return retdata;
}
